import React, { useState } from "react";
import { FormDialog, Form, WarningIcon } from "../../../../components";
import { ArcActions } from "../../../../store";
import { useDispatch } from "react-redux";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

export const AllocateModal = (props) => {
  const dispatch = useDispatch();
  const { onClose, taskCount, selectedOrganisation, blueprint_id, title } =
    props;
  const handleAllocate = () => {
    const payload = {
      ent_org_id: selectedOrganisation.id ? selectedOrganisation.id : "",
      task_blueprint_id: blueprint_id,
      is_allocated: true,
    };
    dispatch(ArcActions.allocateBlueprint(payload));
    props.onClose && props.onClose(true);
  };
  return (
    <FormDialog
      title={"Allocate Compliance Blueprint?"}
      className={`allocate-popup`}
      titleClass={`c238787 f19 bolder`}
      onClose={onClose.bind(null, false)}
      leftBtn={{
        label: (
          <>
            <CloseCircleOutlined className="unallocate-icon" />
            Cancel
          </>
        ),
        color: "#FF0000",
        variant: "lite",
        font: "f14",
        className: "exo2 bolder",
        onClick: onClose.bind(null, false),
      }}
      rightBtn={{
        label: (
          <>
            <CheckCircleOutlined className="suffix-icon thick-icon" />
            Confirm
          </>
        ),
        color: "#0133CC",
        font: "f14",
        className: "exo2 bolder",
        onClick: handleAllocate,
      }}
    >
      <div className="f16">
        <p>
          <b> {title ? title : "Blueprint"}</b> will be allocated to{" "}
          <b>
            {selectedOrganisation.label
              ? selectedOrganisation.label
              : "Organisation"}
          </b>
          .
        </p>
        <p>
          Task Count: <b>{taskCount || 0}.</b>
        </p>
        <p>Organiser will be notified.</p>
      </div>
    </FormDialog>
  );
};
export const DeAllocateModal = (props) => {
  const Fields = [
    {
      label: "",
      attribute: "blueprint_id",
      placeholder: "x0000CMP",
      type: "text",
      autocomplete: "off",
      prefix: "!",
    },
  ];
  const dispatch = useDispatch();
  const {
    onClose,
    taskCount,
    selectedOrganisation,
    blueprint_id,
    title,
    task_blueprint_id,
  } = props;
  const [state, setState] = useState({
    formData: { blueprint_id: "" },
    error: {},
  });
  // Check if the input matches the blueprint_id
  const isUnallocateDisabled = state.formData?.blueprint_id !== blueprint_id;
  const handleDeAllocate = () => {
    const payload = {
      ent_org_id: selectedOrganisation.id ? selectedOrganisation.id : "",
      task_blueprint_id: task_blueprint_id,
      is_allocated: false,
    };
    dispatch(ArcActions.allocateBlueprint(payload));
    props.onClose && props.onClose(true);
  };
  const handleValueChange = (e) => {
    const { error } = state,
      { name, value } = e.target;
    const formData = { ...state.formData };
    formData[name] = value;
    error[name] = "";
    setState((_) => ({ ..._, formData, error: { ...error } }));
  };
  return (
    <FormDialog
      title={"Deallocate Compliance Blueprint?"}
      className={`deallocate-popup`}
      titleClass={`cFF0000 f19 bolder`}
      onClose={onClose.bind(null, false)}
      leftBtn={{
        label: (
          <>
            <CloseCircleOutlined className="unallocate-icon" />
            Unallocate
          </>
        ),
        color: "#FF0000",
        font: "f14",
        className: "exo2 bolder",
        onClick: handleDeAllocate,
        variant: "lite",
        disabled: isUnallocateDisabled,
      }}
      rightBtn={{
        label: (
          <>
            <CloseCircleOutlined className="suffix-icon thick-icon" />
            Cancel
          </>
        ),
        color: "#0133CC",
        font: "f14",
        className: "exo2 bolder",
        onClick: onClose.bind(null, false),
      }}
    >
      <div className="f16">
        <p>
          {" "}
          <WarningIcon className="small-warning-icon" />
          <b> {title ? title : "Blueprint"}</b> will be deallocated to{" "}
          <b>
            {selectedOrganisation.label
              ? selectedOrganisation.label
              : "Organisation"}
          </b>
          .
        </p>
        <p>
          Task Count: <b>{taskCount || 0}.</b>
        </p>
        <p>Organiser will be notified.</p>
        <p>Type the Blueprint ID below to confirm this action.</p>
        <Form
          fieldClass="form-field"
          formData={state.formData}
          error={state.error}
          Fields={Fields}
          onChange={handleValueChange}
        />
      </div>
    </FormDialog>
  );
};
